import React, { Suspense, useMemo } from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, Stack, useStyles2 } from '@grafana/ui';

import { BytesPanel, SkeletonBytesPanel } from '../BytesPanel';
import { QueryResultHeader } from '../QueryResultHeader';
import { getAbsoluteTotalVolume, getSummaryVolumes } from './utils';
import { useMetrics, useRecommendations } from '@/hooks/api-hooks';
import { useSelectedItems } from '@/hooks/context-hooks';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    arrow: css({ marginLeft: theme.spacing(-3), marginRight: theme.spacing(-3), marginTop: theme.spacing(3) }),
    panels: css({ marginTop: theme.spacing(3) }),
  };
};

const Component = () => {
  const styles = useStyles2(getStyles);

  const { data: metrics, error: metricsError, isError: metricsIsError, isLoading: metricsIsLoading } = useMetrics();
  const { data: recommendations } = useRecommendations();
  const { selectedItemsAsArray } = useSelectedItems();

  const affectedRecommendations = useMemo(
    () =>
      selectedItemsAsArray.map((pattern) => {
        return recommendations.mappedItems.get(pattern)!;
      }),
    [recommendations.mappedItems, selectedItemsAsArray]
  );

  const [relativeAfterDrop, relativeTotalVolume] = useMemo(() => {
    return getSummaryVolumes(!selectedItemsAsArray.length ? recommendations.items : affectedRecommendations);
  }, [affectedRecommendations, recommendations.items, selectedItemsAsArray.length]);

  const absoluteTotalVolume = useMemo(() => getAbsoluteTotalVolume(metrics), [metrics]);

  const projectedSavings = relativeTotalVolume - relativeAfterDrop;

  const ingestVolume = absoluteTotalVolume !== null ? absoluteTotalVolume : relativeTotalVolume;
  const projectedVolume = absoluteTotalVolume !== null ? absoluteTotalVolume - projectedSavings : relativeAfterDrop;

  const calculatedReduction = ingestVolume - projectedVolume;

  const differencePanel =
    calculatedReduction >= 0 ? (
      <BytesPanel bytes={calculatedReduction} decimals={2} color={'green'} title="Projected savings" />
    ) : (
      <BytesPanel bytes={-calculatedReduction} decimals={2} color={'red'} title="Projected increase" />
    );

  if (metricsIsLoading || !metrics) {
    return (
      <QueryResultHeader errors={[metricsError]} isErrorArr={[metricsIsError]} isLoadingArr={[metricsIsLoading]} />
    );
  }

  return (
    <div className={styles.panels}>
      <Stack direction={'row'} alignItems={'self-start'} justifyContent={'space-between'}>
        <BytesPanel bytes={ingestVolume} decimals={2} color={'orange'} title="Ingest volume" />
        <Icon className={styles.arrow} name="arrow-right" size="xl" />
        <BytesPanel
          bytes={projectedVolume}
          previousBytes={ingestVolume}
          decimals={2}
          color={'blue'}
          title="Projected volume"
        />
        {differencePanel}
      </Stack>
    </div>
  );
};

const FallbackComponent = () => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.panels}>
      <Stack direction={'row'} alignItems={'self-start'} justifyContent={'space-between'}>
        <SkeletonBytesPanel title="Ingest volume" />
        <Icon className={styles.arrow} name="arrow-right" size="xl" />
        <SkeletonBytesPanel title="Projected volume" />
        <SkeletonBytesPanel title="Projected savings" />
      </Stack>
    </div>
  );
};

export const SavingsPreviewPanels = () => {
  return (
    <Suspense fallback={<FallbackComponent />}>
      <Component />
    </Suspense>
  );
};
