import React, { useMemo } from 'react';

import { intersection as lodashIntersection, union as lodashUnion } from 'lodash';

import { Checkbox } from '@grafana/ui';

import { useSelectedItems, useTableData } from '@/hooks/context-hooks';

export const TableHeaderCheckbox = () => {
  const { selectedItemsAsArray, setSelectedItems } = useSelectedItems();
  const { tableData } = useTableData();

  const selectablePatterns = useMemo(() => {
    return tableData.filter((item) => !item.locked).map((item) => item.pattern);
  }, [tableData]);

  const selectAllState = useMemo(() => {
    const selectedPatterns = lodashIntersection(selectablePatterns, selectedItemsAsArray);

    if (selectablePatterns.length && selectedPatterns.length === selectablePatterns.length) {
      return 'all';
    } else if (selectedPatterns.length > 0 || selectedItemsAsArray.length) {
      return 'partial';
    }
    return 'none';
  }, [selectablePatterns, selectedItemsAsArray]);

  const toggleSelectAll = () => {
    if (selectAllState === 'all') {
      setSelectedItems(new Set());
      return;
    }
    setSelectedItems(new Set<string>(lodashUnion(selectedItemsAsArray, selectablePatterns)));
  };

  return (
    <Checkbox
      indeterminate={selectAllState === 'partial'}
      onChange={toggleSelectAll}
      checked={selectAllState === 'all'}
    />
  );
};
