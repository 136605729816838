import React from 'react';

import { css } from '@emotion/css';
import SVG from 'react-inlinesvg';

import { GrafanaTheme2 } from '@grafana/data';
import { Alert, useStyles2, useTheme2 } from '@grafana/ui';

import { paths } from '@/utils/constants';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    graphic: css({
      margin: theme.spacing(10),
      maxHeight: '300px',
    }),
    graphicContainer: css({
      display: 'flex',
      justifyContent: 'center',
    }),
  };
};

export const AccessDenied = () => {
  const styles = useStyles2(getStyles);
  const theme = useTheme2();
  return (
    <>
      <Alert title="Access denied" severity="error">
        You are not permitted to access this page. Please contact your administrator for more information.
      </Alert>
      <div className={styles.graphicContainer}>
        <SVG
          className={styles.graphic}
          src={
            theme.isDark
              ? `${paths.pluginPublic}/img/grot_401err.svg`
              : `${paths.pluginPublic}/img/grot_401err_light.svg`
          }
        />
      </div>
    </>
  );
};
