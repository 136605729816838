import React from 'react';

import { AppRootProps } from '@grafana/data';

import { AccessDenied } from '@/components/AccessDenied';
import PageWrapper from '@/components/PageWrapper';
import { AppPluginSettings } from '@/pages/AppConfig';

export const NoAccess = (props: AppRootProps<AppPluginSettings>) => {
  return (
    <PageWrapper logo={props.meta.info.logos.large}>
      <AccessDenied />
    </PageWrapper>
  );
};
