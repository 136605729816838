import React from 'react';

import { Checkbox, Tooltip } from '@grafana/ui';

import { PatternRecommendation } from '@/api/types';
import { useSelectedItems } from '@/hooks/context-hooks';

interface Props {
  item: PatternRecommendation;
}

export const ItemCheckbox = ({ item }: Props) => {
  const { selectedItems, toggleSelectedItem } = useSelectedItems();

  const isItemSelected = selectedItems.has(item.pattern);

  const onChange = () => {
    toggleSelectedItem(item.pattern);
  };

  if (item.locked) {
    return (
      <Tooltip content="This pattern is locked">
        <div>
          <Checkbox onChange={onChange} checked={isItemSelected} disabled={item.locked} />
        </div>
      </Tooltip>
    );
  }

  return <Checkbox onChange={onChange} checked={isItemSelected} />;
};
