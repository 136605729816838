import React, { ReactNode, useMemo } from 'react';

import { css } from '@emotion/css';
import Skeleton from 'react-loading-skeleton';

import { NavModelItem, PageLayoutType } from '@grafana/data';
import { PluginPage } from '@grafana/runtime';
import { Icon, useTheme2 } from '@grafana/ui';

interface Props {
  children: ReactNode;
  logo?: string;
}

export default function PageWrapper({ children, logo }: Props): React.JSX.Element {
  const pageNav = useMemo(
    () =>
      ({
        hideFromBreadcrumbs: true,
        img: logo,
        subTitle: (
          <>
            Log analysis and recommendations to reduce your volume.{' '}
            <a
              className={'external-link'}
              target="_blank"
              href="https://grafana.com/docs/grafana-cloud/cost-management-and-billing/reduce-costs/logs-costs/adaptive-logs"
              rel="noreferrer"
            >
              Documentation <Icon size="sm" name="external-link-alt" />
            </a>
          </>
        ) as ReactNode,
        text: 'Adaptive Logs',
      } as NavModelItem),
    [logo]
  );

  return (
    <PluginPage pageNav={pageNav} layout={PageLayoutType.Standard}>
      <div className="adaptive-logs-app">{children}</div>
    </PluginPage>
  );
}

export const PageWrapperSkeleton = () => {
  const theme = useTheme2();
  return (
    <div
      data-testid={'page-wrapper-skeleton'}
      className={css`
        padding: ${theme.spacing(2)};
      `}
    >
      <Skeleton height={theme.spacing(20)} />
      <Skeleton height={theme.spacing(20)} />
      <Skeleton count={15} height={theme.spacing(4)} />
    </div>
  );
};
