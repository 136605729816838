import React from 'react';

import { ConfirmModal } from '@grafana/ui';

import { ConfirmModalLoader } from '../ConfirmationModalLoader';
import { SavingsPreviewPanels } from '../SavingsPreviewPanels';
import { PatternRecommendation } from '@/api/types';
import { useRecommendations, useUpdateRecommendationsMutation } from '@/hooks/api-hooks';
import { useSelectedItems } from '@/hooks/context-hooks';
import { noop } from '@/utils/methods';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export function ApplyModal({ isOpen, onClose }: Props) {
  const { data: recommendations, isFetching: recommendationsIsFetching } = useRecommendations();
  const { isPending: recommendationsUpdating, mutateAsync: updateRecommendationsAsync } =
    useUpdateRecommendationsMutation();
  const { clearSelection, selectedItems } = useSelectedItems();

  const onConfirm = async () => {
    let updatedRecommendations: PatternRecommendation[] = [];

    if (selectedItems.size) {
      const mappedRecommendations = new Map(recommendations.mappedItems);
      selectedItems.forEach((pattern) => {
        const recommendation = mappedRecommendations.get(pattern);

        if (!recommendation) {
          return;
        }

        mappedRecommendations.set(pattern, {
          ...recommendation,
          configured_drop_rate: recommendation.locked
            ? recommendation.configured_drop_rate
            : recommendation?.recommended_drop_rate,
        });
      });

      updatedRecommendations = Array.from(mappedRecommendations.values());
    } else {
      updatedRecommendations = recommendations.items.map((recommendation) => ({
        ...recommendation,
        configured_drop_rate: recommendation.locked
          ? recommendation.configured_drop_rate
          : recommendation.recommended_drop_rate,
      }));
    }

    await updateRecommendationsAsync(updatedRecommendations);
    clearSelection();
  };

  const busy = recommendationsUpdating || recommendationsIsFetching;

  const getConfirmButtonText = (isLoading?: boolean) => {
    return isLoading ? ((<ConfirmModalLoader text="Applying..." />) as unknown as string) : 'Apply';
  };

  return (
    <ConfirmModal
      title="Apply recommended drop rates"
      isOpen={isOpen}
      onDismiss={busy ? noop : onClose}
      confirmText={getConfirmButtonText(busy)}
      dismissText="Cancel"
      confirmButtonVariant="primary"
      onConfirm={async () => {
        await onConfirm();
        onClose();
      }}
      body={
        <>
          {selectedItems.size
            ? 'This action will apply the selected patterns to their recommended rates'
            : 'This action will apply all patterns to their recommended rates'}
          . Are you sure you want to apply changes?
          <SavingsPreviewPanels />
        </>
      }
    />
  );
}
