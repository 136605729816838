import React from 'react';

import { css, cx } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

const getContentBoxStyles = (theme: GrafanaTheme2) => ({
  box: css({
    backgroundColor: theme.colors.background.secondary,
    padding: theme.spacing(2),
  }),
});

export const ContentBox = ({ children, className }: React.PropsWithChildren<{ className?: string }>) => {
  const styles = useStyles2(getContentBoxStyles);

  return <div className={cx(styles.box, className)}>{children}</div>;
};
