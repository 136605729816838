import React, { useState } from 'react';

import { css, cx } from '@emotion/css';
import { debounce as lodashDebounce } from 'lodash';

import { Field, Icon, Input, useStyles2 } from '@grafana/ui';

import { useFilter } from '@/hooks/context-hooks';
import { useRecommendationFilterCountMessage } from '@/hooks/util-hooks';

const getStyles = () => ({
  filterField: css({
    marginBottom: 0,
  }),
});

export const FilterField = ({ className, placeholder }: { className?: string; placeholder?: string }) => {
  const styles = useStyles2(getStyles);
  const { setFilter } = useFilter();
  const [inputDisplay, setInputDisplay] = useState('');

  // Debounce to wait until 0.3s until users stop typing before updating filter
  const debouncedOnChange = lodashDebounce((newFilter) => {
    setFilter(newFilter);
  }, 300);

  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    const newFilter = event.currentTarget.value;
    setInputDisplay(newFilter);
    debouncedOnChange(newFilter);
  };

  const filterMessage = useRecommendationFilterCountMessage();

  return (
    <Field className={cx(styles.filterField, className)} label="Filter">
      <Input
        suffix={filterMessage}
        data-testid="pattern-filter"
        prefix={<Icon name="search" />}
        placeholder={placeholder || 'Pattern'}
        value={inputDisplay}
        onChange={onChange}
      />
    </Field>
  );
};
