import React, { createContext, useState } from 'react';

import { SelectableValue } from '@grafana/data';

import { PatternRecommendation } from '@/api/types';

type AdaptiveLogsContextType = {
  filter: string;
  selectedItems: Set<string>;
  serviceNameFilter?: Array<SelectableValue<string>>;
  setFilter: (filter: string) => void;
  setSelectedItems: (selectedItems: Set<string>) => void;
  setServiceNameFilter: (serviceNames: Array<SelectableValue<string>>) => void;
  setTableData: (tableData: PatternRecommendation[]) => void;
  tableData: PatternRecommendation[];
  toggleSelectedItem: (pattern: string) => void;
};

export const AdaptiveLogsContext = createContext<AdaptiveLogsContextType>({} as AdaptiveLogsContextType);

interface Props {
  children: React.ReactNode;
}

export const AdaptiveLogsContextProvider = ({ children }: Props) => {
  const [filter, setFilter] = useState<string>('');
  const [serviceNameFilter, setServiceNameFilter] = useState<Array<SelectableValue<string>>>([]);
  const [selectedItems, setSelectedItems] = useState(new Set<string>());
  const [tableData, setTableData] = useState<PatternRecommendation[]>([]);

  const toggleSelectedItem = (pattern: string) => {
    if (selectedItems.has(pattern)) {
      selectedItems.delete(pattern);
    } else {
      selectedItems.add(pattern);
    }

    setSelectedItems(new Set<string>(selectedItems));
  };

  return (
    <AdaptiveLogsContext.Provider
      value={{
        filter,
        selectedItems,
        serviceNameFilter,
        setFilter,
        setSelectedItems,
        setServiceNameFilter,
        setTableData,
        tableData,
        toggleSelectedItem,
      }}
    >
      {children}
    </AdaptiveLogsContext.Provider>
  );
};
