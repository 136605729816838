import React, { useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { flatten as lodashFlatten } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Field, MultiSelect, useStyles2 } from '@grafana/ui';

import { BatchEditPattern } from './BatchEditPattern';
import { FilterField } from './FilterField';
import { ApplyModal } from '@/components/ApplyModal';
import { ContentBox } from '@/components/ContentBox';
import { useRecommendations } from '@/hooks/api-hooks';
import { useSelectedItems, useServiceNameFilter, useUserPermissions } from '@/hooks/context-hooks';

export const DIVISIONS = 5;
export const LARGE_SPAN = Math.floor(2 * (DIVISIONS / 3));
const SMALL_SPAN = Math.floor(2 * (DIVISIONS / 4));

const getStyles = (theme: GrafanaTheme2, canApplyPatterns: boolean) => {
  return {
    base: css({
      display: 'grid',
      gridTemplateColumns: '3fr 2fr',
      marginTop: theme.spacing(1),
    }),
    /**
     * TODO: A PR https://github.com/grafana/grafana/pull/93858 for this is merged in the
     * grafana/grafana repo we will wait for the update on that and apply the
     * validationMessageHorizontalOverflow prop in the InlineField instead of having this css
     *  */
    errorFix: css`
      div[role='alert'] {
        position: fixed;
      }
    `,
    filters: css({
      display: 'grid',
      gap: theme.spacing(1),
      gridTemplateColumns: `repeat(${DIVISIONS}, 1fr)`,
    }),
    flexRowContent: css({
      alignItems: 'flex-end',
      display: 'flex',
      gap: theme.spacing(1),
      justifyContent: 'flex-end',
    }),
    fullSpan: css({
      gridColumn: `span ${DIVISIONS}`,
    }),
    largeSpan: css({
      flexGrow: 1,
      gridColumn: `span ${LARGE_SPAN}`,
    }),
    maxDropRate: css({
      display: 'grid',
      gridGap: theme.spacing(1),
    }),
    numberInput: css({
      input: {
        textAlign: 'right',
      },
    }),
    selectedText: css({
      color: theme.colors.text.secondary,
      display: 'inline-block',
      fontStyle: 'italic',
      marginRight: theme.spacing(1),
    }),
    smallSpan: css({
      gridColumn: `span ${SMALL_SPAN}`,
    }),
  };
};

export const PageHeader = () => {
  const [applyOpen, setApplyOpen] = useState(false);
  const userPermissions = useUserPermissions();
  const styles = useStyles2(getStyles, userPermissions.canApplyPatterns);
  const { serviceNameFilter, setServiceNameFilter } = useServiceNameFilter();
  const { data: recommendations } = useRecommendations();
  const { clearSelection, selectedItems } = useSelectedItems();

  const serviceNames = useMemo(() => {
    const attributions = recommendations?.items.map((item) => Object.keys(item.attribution));

    return [...new Set(lodashFlatten(attributions))].map((attribution) => {
      const name = attribution.substring(attribution.indexOf('"') + 1, attribution.lastIndexOf('"'));
      return {
        label: name,
        text: name,
        value: name,
      };
    });
  }, [recommendations?.items]);

  const applyButtonText = !!selectedItems.size
    ? `Apply selected recommendation${selectedItems.size > 1 ? 's' : ''}`
    : 'Apply all recommendations';

  return (
    <ContentBox className={styles.base}>
      <div className={styles.filters}>
        <Field label="Filter by service" className={styles.smallSpan}>
          <MultiSelect
            data-testid="service-filter"
            isSearchable
            isClearable
            options={serviceNames}
            value={serviceNameFilter}
            placeholder="All services"
            onChange={(v) => {
              setServiceNameFilter(v);
            }}
          />
        </Field>
        <FilterField className={styles.largeSpan} />
        <div className={styles.fullSpan}>
          {/* TODO #303 add toggle selected switch */}
          <span className={styles.selectedText}>{selectedItems.size} selected</span>
          {selectedItems.size > 0 && (
            <Button
              data-testid={'clear-selection-button'}
              variant={'primary'}
              fill={'text'}
              onClick={clearSelection}
              icon={'times-circle'}
              tooltip={'Clear selection'}
            />
          )}
        </div>
      </div>

      {/* TODO #302 refactor this part to bundle the button and the modal */}
      <div className={styles.flexRowContent}>
        {userPermissions.canApplyPatterns && (
          <>
            <BatchEditPattern />
            <Button onClick={() => setApplyOpen(true)}>{applyButtonText}</Button>
          </>
        )}
      </div>
      <ApplyModal
        isOpen={applyOpen}
        onClose={() => {
          setApplyOpen(false);
        }}
      />
    </ContentBox>
  );
};
