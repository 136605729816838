import React from 'react';

import { css } from '@emotion/css';

import { LoadingPlaceholder, useStyles2 } from '@grafana/ui';

interface Props {
  text: string;
}

const getStyles = () => ({
  loader: css({
    marginBottom: 0,
  }),
});

export const ConfirmModalLoader = ({ text }: Props) => {
  const styles = useStyles2(getStyles);

  return <LoadingPlaceholder className={styles.loader} text={text} />;
};
