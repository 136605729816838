import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import { FetchError, getBackendSrv } from '@grafana/runtime';

import { DEFAULT_GET_KEY, getWithMappedItems } from '@/api';
import { getPatternRecommendationKey, Metrics, PatternRecommendation } from '@/api/types';
import { errorAlert, successAlert } from '@/utils/alert';
import { paths, QUERY_KEYS } from '@/utils/constants';

export const useRecommendations = () =>
  useSuspenseQuery({
    queryFn: () => getWithMappedItems(paths.recommendations, getPatternRecommendationKey, { format: 'json' }),
    queryKey: [QUERY_KEYS.recommendations],
  });

export const useUpdateRecommendationsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, FetchError, PatternRecommendation[]>({
    mutationFn: async (recommendations) => {
      await getBackendSrv().post(paths.recommendations, recommendations, {
        headers: { 'Content-Type': 'application/json' },
        showErrorAlert: false,
      });
    },
    onError: async (error: FetchError) => {
      errorAlert(error.data.message);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.recommendations] });
      successAlert('Drop rates saved');
    },
  });
};

export const useMetrics = () =>
  useSuspenseQuery({
    queryFn: () => getBackendSrv().get<Metrics>(paths.metrics),
    queryKey: [QUERY_KEYS.metrics],
  });

// TODO need to pass in a header
export const useSummary = () =>
  useSuspenseQuery({
    queryFn: () =>
      getWithMappedItems(
        paths.summary,
        DEFAULT_GET_KEY // It is not clear if these this keyed map for this, but I am restoring the original functionality before I added `getMap` as a parameter
      ),
    queryKey: [QUERY_KEYS.summary],
  });
