import React from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, IconButton, IconName, useStyles2 } from '@grafana/ui';

import { PatternRecommendation } from '@/api/types';
import { useRecommendations, useUpdateRecommendationsMutation } from '@/hooks/api-hooks';
import { useUserPermissions } from '@/hooks/context-hooks';

const getStyles = (theme: GrafanaTheme2) => ({
  currentRate: css({
    width: '25%',
  }),
  currentRateContainer: css({
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
    marginRight: theme.spacing(2),
  }),
  getLockIconStyle: (locked: boolean, recommendationUpdating: boolean, icon = false) =>
    css({
      color: recommendationUpdating ? undefined : locked ? 'rgba(194, 109, 49, 1)' : undefined,
      marginTop: icon ? theme.spacing(0.4) : undefined,
    }),
});

/**
 * TODO This is a temporary component to hold the display of current droprate and the
 * lock button because we don't have the exemption page out yet, the lock icon needs to
 * stay on the page functional until then
 */
export const CurrentRate = ({ recommendation }: { recommendation: PatternRecommendation }) => {
  const { configured_drop_rate, locked, pattern } = recommendation;
  const styles = useStyles2(getStyles);
  const { data: recommendations } = useRecommendations();
  const { isPending: recommendationUpdating, mutateAsync: updateRecommendationsAsync } =
    useUpdateRecommendationsMutation();

  const userPermissions = useUserPermissions();

  const onLockButtonClick = async () => {
    const mappedRecommendations = new Map(recommendations.mappedItems);
    mappedRecommendations.set(pattern, { ...recommendation, locked: !locked });

    await updateRecommendationsAsync(Array.from(mappedRecommendations.values()));
  };

  const icon: IconName = locked ? 'lock' : 'unlock';

  return (
    <div className={styles.currentRateContainer}>
      <div className={styles.currentRate} data-testid="current-rate-text">
        {configured_drop_rate}%
      </div>
      {userPermissions.canApplyPatterns ? (
        <IconButton
          data-testid={`icon-${icon}`}
          aria-label="lock-button"
          className={styles.getLockIconStyle(locked, recommendationUpdating)}
          onClick={onLockButtonClick}
          name={recommendationUpdating ? 'spinner' : locked ? 'lock' : 'unlock'}
        />
      ) : (
        <Icon
          data-testid="current-rate-icon"
          className={styles.getLockIconStyle(locked, true)}
          name={locked ? 'lock' : 'unlock'}
        />
      )}
    </div>
  );
};
