const pluginId = 'grafana-adaptivelogs-app';
export const proxyBasePath = `/api/plugin-proxy/${pluginId}`;

export const paths = {
  appRoot: `/a/${pluginId}`,
  // trailing slash required on flagsApi
  flagsApi: `${proxyBasePath}/flagsApi/`,
  flagsIdentityUpdate: `${proxyBasePath}/flagsApi/identities/update`,
  gcomInstances: `${proxyBasePath}/gcom/instances`,
  metrics: `${proxyBasePath}/metrics`,
  pluginPublic: `/public/plugins/${pluginId}`,
  recommendations: `${proxyBasePath}/recommendations`,
  summary: `${proxyBasePath}/summary`,
} as const;

export const QUERY_KEYS = {
  metrics: 'metrics',
  recommendations: 'recommendations',
  summary: 'summary',
} as const;

export const DEFAULT_DATASOURCE = 'grafanacloud-logs';

export const FLAGSMITH_DEV_CLIENT_KEY = '2CT9eEYfnJ9jJoyhHQDnKi';
export const FLAGSMITH_PROD_CLIENT_KEY = 'QyqpRvtcUSPbpMuWN7yaNS';

export const DROP_RATE_UPPER_LIMIT_EXCLUSIVE = 101;
export const DROP_RATE_LOWER_LIMIT = 0;
export const UPDATED_RATE_INPUT_WIDTH = 8;
