import React from 'react';

import { FallbackProps } from 'react-error-boundary';

import { Alert } from '@grafana/ui';

import PageWrapper from '../PageWrapper';

interface Props {
  detail?: FallbackProps;
  logo: string;
}

export const ErrorBoundaryFallbackRenderer = ({ detail, logo }: Props) => {
  return (
    <PageWrapper logo={logo}>
      <>
        <Alert title="There was a problem with the Adaptive Logs services" severity="error">
          Please contact Grafana Labs support.
        </Alert>
        {detail && (
          <Alert title="Additional Information">
            <pre>{JSON.stringify(detail, null, 2)}</pre>
          </Alert>
        )}
      </>
    </PageWrapper>
  );
};
