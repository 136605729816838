import { isEqual as lodashIsEqual } from 'lodash';

import { OrgRole } from '@grafana/data';
import { config } from '@grafana/runtime';

import { PluginPermissions } from '@/types';

const isUserActionAllowed = (permission: PluginPermissions, fallbackOrgRole: OrgRole) => {
  const { orgRole: userOrgRole, permissions: userPermissions } = config.bootData.user;
  return config.featureToggles.accessControlOnCall
    ? !!userPermissions?.[permission]
    : lodashIsEqual(userOrgRole, fallbackOrgRole);
};

export const getUserPermissions = () => {
  const { permissions } = config.bootData.user;

  return {
    canAccessExplore: !!permissions?.['datasources:explore'],
    canAccessPlugin: isUserActionAllowed('grafana-adaptivelogs-app.plugin:access', OrgRole.Admin),
    canApplyPatterns: isUserActionAllowed('grafana-adaptivelogs-app.patterns:apply', OrgRole.Admin),
    canReadPatterns: isUserActionAllowed('"grafana-adaptivelogs-app.patterns:read', OrgRole.Admin),
    hasPermission: (permission: PluginPermissions) => isUserActionAllowed(permission, OrgRole.Admin),
  };
};
