import { AppEvents, BusEvent } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';

export const errorAlert = (message: string, title = 'Error') => {
  const event: BusEvent = { payload: [title, message], type: AppEvents.alertError.name };
  getAppEvents().publish(event);
};

export const warningAlert = (message: string, title = 'Warning') => {
  const event: BusEvent = { payload: [title, message], type: AppEvents.alertWarning.name };
  getAppEvents().publish(event);
};

export const successAlert = (message: string, title = 'Success') => {
  const event: BusEvent = { payload: [title, message], type: AppEvents.alertSuccess.name };
  getAppEvents().publish(event);
};
