import React, { useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { inRange as lodashInRange, sum as lodashSum } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, ConfirmModal, Field, Input, useStyles2 } from '@grafana/ui';

import { getSummaryVolumes } from '@/components/SavingsPreviewPanels/utils';
import { useRecommendations, useUpdateRecommendationsMutation } from '@/hooks/api-hooks';
import { useSelectedItems, useUserPermissions } from '@/hooks/context-hooks';
import { DROP_RATE_UPPER_LIMIT_EXCLUSIVE, UPDATED_RATE_INPUT_WIDTH } from '@/utils/constants';
import { getValueAndUnit } from '@/utils/formats';
import { noop } from '@/utils/methods';

const getStyles = (theme: GrafanaTheme2) => ({
  modalBody: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  }),
  updatedRateField: css({
    marginTop: theme.spacing(2),
  }),
});

export const BatchEditPattern = () => {
  const styles = useStyles2(getStyles);
  const [isOpen, setIsOpen] = useState(false);
  const [updatedRate, setUpdatedRate] = useState('');
  const userPermissions = useUserPermissions();
  const { isPending: recommendationUpdating, mutateAsync: updateRecommendationsAsync } =
    useUpdateRecommendationsMutation();
  const { data: recommendations } = useRecommendations();
  const { clearSelection, selectedItems, selectedItemsAsArray } = useSelectedItems();

  const selectedRecommendations = useMemo(
    () =>
      selectedItemsAsArray.map((pattern) => {
        return recommendations.mappedItems.get(pattern)!;
      }),
    [recommendations.mappedItems, selectedItemsAsArray]
  );

  const [relativeAfterDrop, relativeTotalVolume] = useMemo(() => {
    return getSummaryVolumes(selectedRecommendations);
  }, [selectedRecommendations]);

  const projectedSavingsWithRecommended = relativeTotalVolume - relativeAfterDrop;
  const projectedSavingsWithCustomValues = useMemo(() => {
    const savings = selectedRecommendations.map((recommendation) => {
      const rate = (100 - Number(updatedRate)) / 100;
      return recommendation.volume * rate;
    });
    return relativeTotalVolume - lodashSum(savings);
  }, [relativeTotalVolume, selectedRecommendations, updatedRate]);

  const onConfirm = async () => {
    const mappedRecommendations = new Map(recommendations.mappedItems);

    selectedItems.forEach((pattern) => {
      const currentRecommendation = mappedRecommendations.get(pattern);

      if (!currentRecommendation) {
        return;
      }

      mappedRecommendations.set(pattern, {
        ...currentRecommendation,
        configured_drop_rate: currentRecommendation.locked
          ? currentRecommendation.configured_drop_rate
          : Math.min(Number(updatedRate), currentRecommendation.recommended_drop_rate),
      });
    });

    await updateRecommendationsAsync(Array.from(mappedRecommendations.values()));
    clearSelection();
    setUpdatedRate('');
    setIsOpen(false);
  };

  return (
    <>
      <Button
        tooltip={!userPermissions.canApplyPatterns ? 'You do not have permission for this action.' : ''}
        variant="secondary"
        disabled={!userPermissions.canApplyPatterns || !selectedItems.size}
        onClick={() => setIsOpen(true)}
      >
        Batch edit rates
      </Button>
      <ConfirmModal
        title="Edit patterns"
        isOpen={isOpen}
        body={
          <div className={styles.modalBody}>
            <div>
              Set up custom drop rate for {selectedItems.size} selected pattern
              {`${selectedItems.size === 1 ? '' : 's'}`}. There are various recommended drop rates with a total{' '}
              {getValueAndUnit(projectedSavingsWithRecommended, 2)} of projected savings.
            </div>
            <Field
              label="Custom drop rate (all selected patterns)"
              invalid={!lodashInRange(Number(updatedRate), DROP_RATE_UPPER_LIMIT_EXCLUSIVE)}
              error="Drop percentage must be between 0% and 100% inclusive"
            >
              <Input
                autoFocus
                aria-label="batch-custom-rate-input"
                width={UPDATED_RATE_INPUT_WIDTH}
                type="number"
                value={updatedRate}
                suffix="%"
                onChange={(v) => setUpdatedRate(v.currentTarget.value)}
              />
            </Field>
            <div>
              Projected savings with the custom drop rate: {getValueAndUnit(projectedSavingsWithCustomValues, 2)}
            </div>
          </div>
        }
        confirmText={recommendationUpdating ? 'Applying...' : 'Apply'}
        disabled={!updatedRate || !lodashInRange(Number(updatedRate), DROP_RATE_UPPER_LIMIT_EXCLUSIVE)}
        confirmButtonVariant={recommendationUpdating ? 'secondary' : 'primary'}
        onConfirm={recommendationUpdating ? noop : onConfirm}
        onDismiss={
          recommendationUpdating
            ? noop
            : () => {
                setUpdatedRate('');
                setIsOpen(false);
              }
        }
      />
    </>
  );
};
