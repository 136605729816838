import React from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { IconButton, useStyles2 } from '@grafana/ui';

import { ContentBox } from '@/components/ContentBox';
import { useCopyToClipboard } from '@/hooks/util-hooks';

interface Props {
  pattern: string;
}

const getStyles = (theme: GrafanaTheme2) => ({
  copyButton: css({
    alignSelf: 'start',
    justifySelf: 'end',
  }),
  pattern: css({
    backgroundColor: theme.colors.background.canvas,
    color: theme.colors.text.secondary,
    display: 'grid',
    fontFamily: theme.typography.fontFamilyMonospace,
    fontSize: theme.typography.bodySmall.fontSize,
    gridTemplateColumns: '30fr 1fr',
    padding: theme.spacing(1),
    wordBreak: 'break-word',
  }),
});

export const PatternDisplay = ({ pattern }: Props) => {
  const styles = useStyles2(getStyles);
  const [isCopied, doCopy] = useCopyToClipboard(pattern);

  const iconName = isCopied ? 'check' : 'copy';

  return (
    <div>
      <h3>Pattern</h3>
      <ContentBox className={styles.pattern}>
        {pattern}
        <IconButton
          data-testid="icon-copy"
          aria-label="copy-icon-button"
          className={styles.copyButton}
          name={iconName}
          onClick={doCopy}
        />
      </ContentBox>
    </div>
  );
};
