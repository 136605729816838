import { Recommendations } from '@/pages/Recommendations';
import { PageDefinition, PageType } from '@/types';

export const pages = new Map<PageType, PageDefinition>([
  [
    'recommendations',
    {
      component: Recommendations,
      id: 'recommendations',
      text: 'Recommended patterns',
    },
  ],
]);
