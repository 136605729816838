import { useCallback, useEffect, useMemo, useState } from 'react';

import copy from 'copy-to-clipboard';

import { SelectableValue } from '@grafana/data';

import { PatternRecommendation } from '@/api/types';
import { useRecommendations } from '@/hooks/api-hooks';
// import { useFilter } from '@/hooks/context-hooks';
// import { Recommendations } from '@/pages/Recommendations';
import { filterRecommendations } from '@/utils/filter';

type ReccomendationFilterCounts = {
  recommendationsFiltered?: number;
  recommendationsLoaded?: number;
};

export function useRecommendationFilterCounts(): ReccomendationFilterCounts {
  const filterCheck = useFilterCheck();

  const { data } = useRecommendations();

  return {
    recommendationsFiltered: filterCheck?.size,
    recommendationsLoaded: data?.items.length,
  };
}

export function useRecommendationFilterCountMessage() {
  const { recommendationsFiltered, recommendationsLoaded } = useRecommendationFilterCounts();

  const filterCountMessage = useMemo(() => {
    if (!recommendationsLoaded) {
      return null;
    }

    if (recommendationsFiltered === undefined) {
      return `Showing ${recommendationsLoaded} patterns`;
    }

    return `Showing ${recommendationsFiltered} of ${recommendationsLoaded} patterns`;
  }, [recommendationsFiltered, recommendationsLoaded]);

  return filterCountMessage;
}

export const useCopyToClipboard = (text: string, duration = 2500): [boolean, () => void] => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      const id = setTimeout(() => {
        setIsCopied(false);
      }, duration);
      return () => {
        clearTimeout(id);
      };
    }
    return () => void 0;
  }, [isCopied, duration]);

  return [
    isCopied,
    useCallback(() => {
      const didCopy = copy(text, { debug: true });
      setIsCopied(didCopy);
    }, [setIsCopied, text]),
  ];
};

/**
 * TODO this is being used in two places right now
 * RecommendationsTable and PageHeader, the one in
 * PageHeader will be removed after the new UI, thus
 * we can remove this hook and have the filter directly
 * down in the RecommendationsTable
 */
type FilterCheck = {
  has: (pattern: string) => boolean;
  size: number;
} | null;

export const useFilterCheck = (
  recommendations?: PatternRecommendation[],
  serviceNameFilter?: Array<SelectableValue<string>>,
  filter?: string
): FilterCheck | null => {
  const filterCheck = useMemo(() => {
    const serviceNameFilterSet = new Set(serviceNameFilter?.map((serviceName) => serviceName.value || ''));
    const acceptedIndices = filterRecommendations(recommendations, filter, serviceNameFilterSet);

    // TODO, these indices could be re-used as a performance boost in ufuzzy in certain cases
    if (!recommendations || acceptedIndices === null) {
      // Nothing is filtered out
      return null;
    }

    const filteredIn = new Set(acceptedIndices.map((index) => recommendations[index].pattern));

    return filteredIn;
  }, [serviceNameFilter, recommendations, filter]);

  return filterCheck;
};
