import React from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { EditPattern } from './EditPattern';
import { ExploreLinkButton } from './ExploreLinkButton';
import { PatternRecommendation } from '@/api/types';
import { useUserPermissions } from '@/hooks/context-hooks';

interface Props {
  recommendation: PatternRecommendation;
}

const getStyles = (theme: GrafanaTheme2) => ({
  actionsContainer: css({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  }),
  editIcon: css({
    textAlign: 'center',
  }),
});

export const PatternActions = ({ recommendation }: Props) => {
  const userPermissions = useUserPermissions();
  const styles = useStyles2(getStyles);

  return (
    <div className={userPermissions.canAccessExplore ? styles.actionsContainer : styles.editIcon}>
      <EditPattern recommendation={recommendation} />
      {userPermissions.canAccessExplore && <ExploreLinkButton recommendation={recommendation} />}
    </div>
  );
};
