export function isErrorType(error: unknown): error is ErrorType {
  if (typeof error !== 'object' || error === null) {
    return false;
  }

  const e = error as any;

  return (
    typeof e.status === 'number' &&
    typeof e.statusText === 'string' &&
    e.data != null &&
    typeof e.data.status === 'string' &&
    typeof e.data.error === 'string' &&
    typeof e.data.message === 'string' &&
    e.config != null &&
    typeof e.config.url === 'string' &&
    typeof e.config.method === 'string' &&
    typeof e.config.headers === 'object'
  );
}

export type ErrorType = {
  config: {
    headers: Record<string, string>;
    method: string;
    url: 'api/plugin-proxy/grafana-adaptive-metrics-app/${string}';
  };
  data: {
    error: string;
    message: string;
    status: string;
  };
  status: number;
  statusText: string;
};
