export type PatternRecommendation = {
  // TODO fix type
  attribution: any;
  configured_drop_rate: number;
  ingested_lines: number;
  locked: boolean;
  pattern: string;
  queried_lines: number;
  recommended_drop_rate: number;
  volume: number;
};

export function getPatternRecommendationKey(recommendation: PatternRecommendation) {
  return recommendation.pattern;
}

export type Summary = {
  dropped_volume: number;
  kept_volume: number;
  total_ingested_volume: number;
  unqueried_volume: number;
};

export type Metrics = {
  dropped_volume: number;
  ingested_volume: number;
};

export type ListResponseWithHeaders<Key, T> = {
  duplicateItems: T[];
  items: T[];
  mappedItems: Map<Key, T>;
};
