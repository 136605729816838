import React, { useMemo } from 'react';

import { some as lodashSome } from 'lodash';

import { Alert, LoadingPlaceholder } from '@grafana/ui';

import { ErrorType, isErrorType } from './types';
import { proxyBasePath } from '@/utils/constants';

type Props = {
  errors?: unknown[];
  isErrorArr: boolean[];
  isLoadingArr: boolean[];
};

const removeProxyBaseUrl = (error: ErrorType) => {
  const SERVICE_PREFIX = proxyBasePath.substring(1) + '/';
  return error.config.url.replace(SERVICE_PREFIX, '');
};

export function QueryResultHeader({ errors, isErrorArr, isLoadingArr }: Props) {
  const [serviceErrors, unknownErrors] = useMemo(() => {
    const map = new Map<string, Set<string>>();

    const unknowns: unknown[] = [];

    errors?.forEach((error) => {
      if (isErrorType(error)) {
        const key = error.data.message;
        const service = removeProxyBaseUrl(error);
        const services = map.get(key);
        if (services) {
          services.add(service);
        } else {
          map.set(key, new Set([service]));
        }
      } else if (error != null) {
        unknowns.push(error);
      }
    });

    return [Array.from(map).map(([error, services]) => ({ error, services: Array.from(services) })), unknowns];
  }, [errors]);

  return (
    <>
      {lodashSome(isLoadingArr) && <LoadingPlaceholder text="Loading" />}
      {lodashSome(isErrorArr) && (
        <Alert
          data-testid="error-alert"
          title="There was a problem retrieving the data from the Adaptive Logs services"
          severity="error"
        >
          <ul>
            {serviceErrors.map(({ error, services }) => (
              <li key={error}>
                <div>
                  {error}{' '}
                  {services.map((service) => (
                    <code key={service}>{service}</code>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        </Alert>
      )}

      {unknownErrors?.length > 0 && (
        <Alert title="Additional problems">
          <pre>{JSON.stringify(unknownErrors, null, 2)}</pre>
        </Alert>
      )}
    </>
  );
}
