import React, { ComponentProps, useEffect, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { isEqual as lodashIsEqual } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { Column, InteractiveTable, useStyles2 } from '@grafana/ui';

import { Bytes } from '../Bytes';
import { ItemCheckbox } from '../ItemCheckbox';
import { PatternActions } from '../PatternActions';
import { PatternSummary } from '../PatternSummary';
import { TableHeaderCheckbox } from '../TableHeaderCheckbox';
import { CurrentRate } from './CurrentRate';
import { PatternRecommendation } from '@/api/types';
import { useFilter, useServiceNameFilter, useTableData } from '@/hooks/context-hooks';
import { useFilterCheck } from '@/hooks/util-hooks';
import { formatNumber } from '@/utils/formats';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    columnHeaderUpperText: css({
      fontSize: theme.typography.bodySmall.fontSize,
      position: 'absolute',
      top: theme.spacing(-1.5),
    }),
    multiLineHeader: css({
      display: 'inline-block',
      position: 'relative',
    }),
    noRows: css({
      color: theme.colors.text.secondary,
      fontStyle: 'italic',
      margin: `${theme.spacing(2)} auto 0`,
    }),
    // idea from https://stackoverflow.com/a/44452722
    patternCell: css({
      '::after': {
        color: theme.colors.text.primary,
        content: 'attr(data-pattern)',
        left: 0,
        overflow: 'hidden',
        pointerEvents: 'none',
        position: 'absolute',
        right: 0,
        textOverflow: 'ellipsis',
        top: 0,
        whiteSpace: 'nowrap',
      },
      color: 'transparent',
      maxHeight: '1.5em',
      overflow: 'hidden',
      position: 'relative',
      wordBreak: 'break-word',
    }),
    rightAlign: css({
      marginRight: theme.spacing(2.5),
      textAlign: 'right',
    }),
  };
};

const headerTooltips: ComponentProps<typeof InteractiveTable>['headerTooltips'] = {
  configured_drop_rate: {
    content: 'Percentage of log lines currently being dropped',
    iconName: 'info-circle',
  },
  drop_rate_comparison: {
    content: 'Comparison between recommended drop rate and current (or modified) drop rate.',
    iconName: 'arrows-h',
  },
  queried_lines: {
    content: 'Total number of log lines returned by all queries which match this pattern.',
    iconName: 'info-circle',
  },
  recommended_drop_rate: {
    content: 'Recommended percent of log lines to drop',
    iconName: 'info-circle',
  },
  savings: {
    content: 'Projected savings with recommended rate',
    iconName: 'info-circle',
  },
  volume: {
    content: 'Ingested volume for past 15 days.',
    iconName: 'info-circle',
  },
};

const Savings = ({ recommendation }: { recommendation: PatternRecommendation }) => {
  const styles = useStyles2(getStyles);
  const savings = recommendation.volume * (recommendation.recommended_drop_rate / 100);

  return <Bytes className={styles.rightAlign} bytes={Math.floor(savings)} decimals={1} />;
};

type Styles = ReturnType<typeof getStyles>;

const getColumns = (styles: Styles): Array<Column<PatternRecommendation>> => {
  return [
    {
      cell: ({ row: { original } }) => {
        const recommendation: PatternRecommendation = original;
        return <ItemCheckbox item={recommendation} />;
      },
      disableGrow: true,
      header: (<TableHeaderCheckbox />) as unknown as string,
      id: 'pattern-selector',
    } as Column<PatternRecommendation>,
    {
      cell: ({ row: { original } }) => (
        <div className={styles.patternCell} data-pattern={original.pattern}>
          {original.pattern}
        </div>
      ),
      header: 'Pattern',
      id: 'pattern',
      sortType: 'string',
    },
    {
      cell: ({ row: { original } }) => {
        return (
          <div className={styles.rightAlign}>
            {formatNumber((original.queried_lines / original.ingested_lines) * 100, 2)}
          </div>
        );
      },
      disableGrow: true,
      header: 'Query : Ingest',
      id: 'queried_lines',
      sortType: 'number',
    },
    {
      cell: ({ row: { original } }) => {
        return <Bytes className={styles.rightAlign} bytes={original.volume} decimals={1} />;
      },
      disableGrow: true,
      header: 'Volume',
      id: 'volume',
      sortType: 'number',
    },
    {
      cell: ({ row: { original } }) => {
        const recommendation: PatternRecommendation = original;

        return <CurrentRate recommendation={recommendation} />;
      },
      disableGrow: true,
      header: 'Current',
      id: 'configured_drop_rate',
      sortType: 'number',
    },
    {
      cell: ({ row: { original } }) => <div className={styles.rightAlign}>{original.recommended_drop_rate}%</div>,
      disableGrow: true,
      header: 'Recommended',
      id: 'recommended_drop_rate',
      sortType: 'number',
    },
    {
      cell: ({ row: { original } }) => {
        return <Savings recommendation={original} />;
      },
      disableGrow: true,
      header: 'Projected Savings',
      id: 'savings',
      sortType: 'number',
    },
    {
      cell: ({ row: { original } }) => <PatternActions recommendation={original} />,
      disableGrow: true,
      header: 'Actions',
      id: 'exploreLink',
    },
  ];
};

interface Props {
  tableData: PatternRecommendation[];
}

export function RecommendationsTable({ tableData }: Props) {
  const { filter, isFiltered } = useFilter();
  const { serviceNameFilter } = useServiceNameFilter();
  const styles = useStyles2(getStyles);
  const [memoizedTableData, setMemoizedTableData] = useState<PatternRecommendation[]>([]);
  const filterCheck = useFilterCheck(tableData, serviceNameFilter, filter);
  const { setTableData } = useTableData();

  const columns = getColumns(styles);

  // When filter updates, triggers the uFuzzy
  const filteredData = useMemo(() => {
    if (filterCheck === null) {
      return tableData;
    } else {
      return tableData.filter((item) => filterCheck.has(item.pattern));
    }
  }, [tableData, filterCheck]);

  useEffect(() => {
    // By doing a deep compare of the filteredData and memoizedTableData, we avoid the table re-rendering sending the
    // user back to the first page when selecting an item (or anything that would cause the filteredData useMemo to fire.
    if (!lodashIsEqual(memoizedTableData, filteredData)) {
      setMemoizedTableData(filteredData);
    }
    setTableData(filteredData);
  }, [filteredData]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderExpandedRow = (recommendation: PatternRecommendation) => {
    return <PatternSummary recommendation={recommendation} />;
  };

  const noDataMessage = `No recommended patterns available ${isFiltered ? 'for the current filter' : ''}`;

  return (
    <>
      <InteractiveTable
        pageSize={15}
        columns={columns}
        data={memoizedTableData}
        headerTooltips={headerTooltips}
        renderExpandedRow={renderExpandedRow}
        getRowId={(rec: PatternRecommendation) => rec.pattern}
      />
      {!filteredData.length && (
        <div className={styles.noRows} data-testid="no-data-message">
          {noDataMessage}
        </div>
      )}
    </>
  );
}
