import React from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { PageHeader } from '@/components/PageHeader';
import { QueryResultHeader } from '@/components/QueryResultHeader';
import { RecommendationsTable } from '@/components/RecommendationsTable';
import { useRecommendations } from '@/hooks/api-hooks';

const getStyles = (theme: GrafanaTheme2) => ({
  filterField: css({
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  }),
  tableContainer: css({
    display: 'flex',
    flexDirection: 'column',
  }),
});

export const Recommendations = () => {
  const styles = useStyles2(getStyles);

  const {
    data: recommendations,
    error: recommendationsError,
    isError: recommendationsIsError,
    isLoading: recommendationsIsLoading,
  } = useRecommendations();

  if (recommendationsIsLoading || !recommendations) {
    return (
      <QueryResultHeader
        errors={[recommendationsError]}
        isErrorArr={[recommendationsIsError]}
        isLoadingArr={[recommendationsIsLoading]}
      />
    );
  }

  return (
    <div>
      <PageHeader />
      <div className={styles.tableContainer}>
        <RecommendationsTable tableData={recommendations.items} />
      </div>
    </div>
  );
};
